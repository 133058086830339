import React, {useEffect} from 'react'
import SEO from 'components/SEO'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {graphql, navigate} from 'gatsby'
import BlogBlockSection from '../sections/BlogBlockSection'
import HeroWithoutSlider from '../components/HeroWithoutSlider'
import GeneralContacts from '../components/GeneralContacts'
import ShowMob from '../components/ShowMob'
import FormSubscribe from '../components/FormSubscribe'
import Header from "../components/Header";
import Layout from "../components/Layout";

export default function BlogsPage({
  pageContext: {
    breadcrumb: {crumbs},
  },
  data,
  pageContext,
  location,
}) {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  useEffect(() => {
    if (typeof window !== 'undefined' && data.strapiBlogsPage.en_disabled) {
      changeLanguage('de')
    }
  }, [])
  return (
    <Layout dataLocales={data} location={location}>
    <Header dataLang={data.strapiBlogsPage.en_disabled} />
      <SEO
        title={data.strapiBlogsPage.seo_title[language]}
        description={data.strapiBlogsPage.seo_description[language]}
      />
      <HeroWithoutSlider
        title={data.strapiBlogsPage.h1_title[language]}
        crumbs={crumbs}
        subtitle={t('sections.blogs.subtitle')}
      />
      <BlogBlockSection
        data={data.allStrapiBlog.edges}
        title={t('sections.blogs.titleSecond')}
        subtitle={t('sections.blogs.subtitleSecond')}
        notSliced
        noAllBlogs
        showCategories
        categoryData={data}
        negativeMargin
        minHeight
        pageContext={pageContext}
        location={location}
      />
      <ShowMob>
        <FormSubscribe />
      </ShowMob>
      <ShowMob>
        <GeneralContacts />
      </ShowMob>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    allStrapiBlog(skip: $skip, limit: $limit, sort: {order: DESC, fields: date_for_sort}) {
      edges {
        node {
          article {
            de
            en
          }
          date {
            de
            en
          }
          description_short {
            de
            en
          }
          navigation {
            de
            en
          }
          reading_time {
            de
            en
          }
          page_url
          date_for_sort
          published_at
          title {
            de
            en
          }
        }
      }
    }
    allStrapiLawyer {
      edges {
        node {
          name
          page_url
          position {
            de
            en
          }
          services {
            name {
              de
              en
            }
          }
        }
      }
    }
    allStrapiService {
      edges {
        node {
          name {
            de
            en
          }
          service_types {
            de
            en
          }
          page_url
        }
      }
    }
    strapiBlogsPage {
      en_disabled
      seo_description {
        de
        en
      }
      seo_title {
        de
        en
      }
      h1_title {
        de
        en
      }
    }
  }
`
